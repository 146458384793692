import React from "react";

const EcoMenuFixedSection = ({ menuItems }) => {
  return (
    <div className="d-flex">
      <div className="px-3">
        <ul className="nav flex-column ">
          {menuItems.map((item) => {
            return item.title ? (
              <li key={item.title} className="nav-item">
                <a className="nav-link py-1" href={item.url} rel="noreferrer">
                  {item.title}
                </a>
              </li>
            ) : (
              <li key={item.title} className="nav-item">
                <div className="dropdown-divider"></div>
              </li>
            );
          })}
        </ul>
      </div>
      <div className="px-3">
        <ul className="nav nav-justified flex-column p-3 ecoMenu__appsContainer">
          <li className="nav-item text-left">Price Your Device On The Go!</li>
          <li className="nav-item text-center">
            <a
              href="https://itunes.apple.com/us/app/ecoatm/id944835823?ls=1&mt=8"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Apple iTunes"
                src="https://cdn.shopify.com/s/files/1/0162/3841/1830/files/F868A635-CDDD-44BD-9490-DAB313F6585B_small.png?v=1564125182"
              ></img>
            </a>
          </li>
          <li className="nav-item text-center">
            <a
              href="https://play.google.com/store/apps/details?id=com.ecoatm.ecoapp.android"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Google Play"
                src="https://cdn.shopify.com/s/files/1/0162/3841/1830/files/5067485F-2B6F-440D-9D96-D0CBD9D7DAF8_small.png?v=1564125198"
              ></img>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default EcoMenuFixedSection;
