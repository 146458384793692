import _ from "lodash";
import admins from "../config.json";
import moment from "moment";
import "moment-timezone";
import { getI18n } from "react-i18next";

const bullseyeAdminURL = process.env.REACT_APP_ADMIN_URL;

const NewId = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return "_" + Math.random().toString(36).substr(2, 9);
};

const FixRelativePath = function (imagePath) {
  if (!imagePath) return imagePath;

  let returnPath = imagePath;

  if (imagePath.toLowerCase().includes("~/resources/")) {
    returnPath = imagePath
      .toLowerCase()
      .replace("~/resources/", bullseyeAdminURL + "resources/");
  }

  // I did this because sometimes the server returns encode url and if I only apply the encodeURI
  // sometimes the urls are double encode
  return encodeURI(decodeURI(returnPath));
};

const splitListByColumns = function (
  originalList,
  numberOfColumns,
  screenSize
) {
  let numberColumns;
  let lists = [];

  switch (screenSize) {
    case "sm":
      numberColumns = originalList.length;
      break;
    case "md":
      numberColumns =
        numberOfColumns > 1 ? originalList.length / 2 : originalList.length;
      break;
    case "lg":
      numberColumns = originalList.length / numberOfColumns;
      break;

    default:
      numberColumns = originalList.length / numberOfColumns;
      break;
  }
  lists = _.chunk(originalList, Math.ceil(numberColumns));

  return lists;
};

const getStrListSplitByComma = function (originalList) {
  let strSplit = "";

  originalList.forEach((e) => {
    if (strSplit !== "") strSplit += ", ";
    strSplit += e.title;
  });
  return strSplit;
};
/** Get a category list from the collection of the JSON,  */
const getListCategoryFromCollectionByExistingCategories = function (
  collection,
  existingCategories
) {
  let returnList = [];
  collection
    .filter((e) => {
      return existingCategories.includes(e.id);
    })
    .forEach((item) => {
      returnList.push({ id: item.id, title: item.name });
    });

  return returnList;
};

function getLocationSocialMedia(contactInformation) {
  const socialMediaInfo = contactInformation.RestLocationSocialMedia;
  const icons = [];

  if (!socialMediaInfo) return icons;

  if (socialMediaInfo.InstagramURL) {
    icons.push({
      name: "instagram",
      icon: "/images/instagram.svg",
      url: admins.instagramMainUrl + socialMediaInfo.InstagramURL,
    });
  }

  if (socialMediaInfo.LinkedInURL) {
    icons.push({
      name: "linkedin",
      icon: "/images/linkedin.svg",
      url: admins.LinkedInMainUrl + socialMediaInfo.LinkedInURL,
    });
  }

  if (contactInformation.FacebookPageId) {
    icons.push({
      name: "facebook",
      icon: "/images/facebook.svg",
      url: admins.facebookMainUrl + contactInformation.FacebookPageId,
    });
  }

  if (socialMediaInfo.TwitterURL) {
    icons.push({
      name: "twitter",
      icon: "/images/twitter.svg",
      url: admins.TwitterMainUrl + socialMediaInfo.TwitterURL,
    });
  }

  if (socialMediaInfo.PinterestURL) {
    icons.push({
      name: "pinterest",
      icon: "/images/pinterest.svg",
      url: admins.PinterestMainUrl + socialMediaInfo.PinterestURL,
    });
  }

  if (socialMediaInfo.YelpURL) {
    icons.push({
      name: "yelp",
      icon: "/images/yelp.svg",
      url: admins.YelpMainUrl + socialMediaInfo.YelpURL,
    });
  }
  return icons;
}

function getStateNameByAbbr(name, region1List) {
  var result = [];
  if (name && region1List) {
    result = region1List.filter(
      (o) => o.Abbr.toLowerCase() === name.toLowerCase()
    );
  }
  return result.length > 0 ? result[0].Name : null; // or undefined
}

function getStateAndCity(state, city, region1List) {
  let content;

  if (state && city) {
    content = `${city},${state}`;
  } else if (state && !city) {
    content = getStateNameByAbbr(state, region1List);
  } else {
    content = "";
  }
  return content;
}

//Convert hours that is in AM/PM to 24H Format
function convertTimeFrom12To24Format(time12) {
  const [sHours, minutes, period] = time12
    .match(/([0-9]{1,2}):([0-9]{2}) (AM|PM)/)
    .slice(1);
  const PM = period === "PM";
  const hours = (+sHours % 12) + (PM ? 12 : 0);

  return `${("0" + hours).slice(-2)}:${minutes}`;
}

const capitalizeFirstLetter = (string) => {
  if (!string) return "";

  let textToConvertFirstLetter = string.charAt(0).toUpperCase();
  return textToConvertFirstLetter + string.toLowerCase().slice(1);
};

const getDateFromUnix = (date) =>{
  // Get the number parts
  var b = date.match(/\d+/g);

  // Get the sign of the offset
  var sign = /-/.test(date)? -1 : +1;

  // Adjust the time value by the offset converted to milliseconds
  // and use to create a Date
  var ms = +b[0] + sign * (b[1].slice(0,2)*3.6e6 + b[1].slice(-2)*6e4);

  return moment(new Date(ms));
}

const getCloseInformation = (daysArray, TimeZone) => {
  const i18n = getI18n();

  if (!i18n) return "";

  let result = null;
  if (daysArray && daysArray.length > 0) {
    //Get Next and currect time
    let finalDaysArray = daysArray.filter((x) => x.NoHoursAvailable === false);
    const currentCloseDateTime = finalDaysArray[0];
    const nextDayCloseDateTime = finalDaysArray[1];

    //Get current day close time
    let currentDayTimeClose = null;
    if (currentCloseDateTime && currentCloseDateTime.HoursDisplayText) {
      currentDayTimeClose = currentCloseDateTime.HoursDisplayText.substring(
        currentCloseDateTime.HoursDisplayText.indexOf("-") + 2
      );
    }

    //Get next day open time
    let nextDayOpenTime = null;
    if (nextDayCloseDateTime && nextDayCloseDateTime.HoursDisplayText) {
      nextDayOpenTime = nextDayCloseDateTime.HoursDisplayText.substring(
        0,
        nextDayCloseDateTime.HoursDisplayText.indexOf("-") - 1
      );
    }

    //Get TimeZone Hours (GTM-6) from string
    let currentDateTime =
      TimeZone && typeof TimeZone !== "number"
        ? moment()
            .utc()
            .utcOffset(
              TimeZone.substring(TimeZone.indexOf("-"), TimeZone.indexOf(")"))
            )
            .format("YYYY-MM-DD HH:mm:ss")
        : moment()
            .utc()
            .utcOffset(TimeZone, false)
            .format("YYYY-MM-DD HH:mm:ss");

    let closeDateTime = moment(
      moment().format("YYYY-MM-DD") +
        " " +
        convertTimeFrom12To24Format(currentDayTimeClose)
    ).format("YYYY-MM-DD HH:mm:ss");

    let finalcurrentDateTime = moment(currentDateTime);
    let finalcloseDateTime = moment(closeDateTime);

    //Diference Between two Dates in Hours
    const diffHours = finalcloseDateTime.diff(finalcurrentDateTime, "hours");

    //Diference Between two Dates in Minutes
    const diffMinutes = finalcloseDateTime.diff(
      finalcurrentDateTime,
      "minutes"
    );

    let todayNextDay = finalcurrentDateTime.add(1, "d");
    let nextDayCloseDateTimeDay = getDateFromUnix(nextDayCloseDateTime.ClosingTime)  

    if (
      diffHours > 0 &&
      todayNextDay.day() ===
      nextDayCloseDateTimeDay.day()
    ) {
      result = `${i18n.t(
        "openCloseHours.openTodayUntil"
      )} ${currentDayTimeClose}`;
    } else if (
      diffHours === 0 &&
      diffMinutes > 0 &&
      todayNextDay.day() ===
      nextDayCloseDateTimeDay.day()
    ) {
      result = `${i18n.t("openCloseHours.closeIn")} ${diffMinutes} min`;
    } else if (
      !nextDayCloseDateTime.NoHoursAvailable &&
      todayNextDay.day() ===
      nextDayCloseDateTimeDay.day()
    ) {
      result = `${i18n.t(
        "openCloseHours.closedUntilTomorrowAt"
      )} ${nextDayOpenTime}`;
    } else if (
      todayNextDay.day() !==
      nextDayCloseDateTimeDay.day()
    ) {
      result = `${i18n.t("openCloseHours.closedUntil")} ${capitalizeFirstLetter(
        nextDayCloseDateTime.NameOfDay.toLowerCase()
      )} ${i18n.t("openCloseHours.at")} ${nextDayOpenTime}`;
    } else {
      result = i18n.t("openCloseHours.closed");
    }
  }

  return result;
};

export {
  NewId,
  FixRelativePath,
  splitListByColumns,
  getStrListSplitByComma,
  getListCategoryFromCollectionByExistingCategories,
  getLocationSocialMedia,
  getStateNameByAbbr,
  getStateAndCity,
  getCloseInformation,
  capitalizeFirstLetter,
};