import React, { useRef } from "react";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
import "./mobileMenu.scss";

const MobileMenu = ({ menuItems }) => {
  const refNavbar = useRef(null);
  return (
    <Navbar
      expand="lg"
      onToggle={(expanded) => {
        document.body.style.overflow = expanded ? "hidden" : "auto";
      }}
    >
      <Navbar.Brand href="https://www.ecoatm.com/">
        {menuItems.logo && (
          <img
            src={menuItems.logo}
            className="d-inline-block align-top"
            style={{ height: 30 }}
            alt="ecoatm logo"
          ></img>
        )}
      </Navbar.Brand>
      <Navbar.Toggle ref={refNavbar} aria-controls="basic-navbar-nav" />

      <Navbar.Collapse
        aria-hidden="true"
        id="basic-navbar-nav"
        className=" drawer "
        onClick={(e) => {
          if ("basic-navbar-nav" === e.target.id) {
            refNavbar.current.click();
          }
        }}
      >
        <div
          className="pl-5 pr-3 pt-3 align-items-start mobileMenu-container"
          aria-hidden="true"
        >
          <div className="w-100 d-block text-right">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
          </div>

          <Nav className="mr-auto text-uppercase mobileMenu--navbar-nav">
            {menuItems.items &&
              menuItems.items.length > 0 &&
              menuItems.items.map((item) => {
                if (item.items && item.items.length > 0) {
                  return (
                    <NavDropdown
                      key={item.title}
                      title={item.title}
                      id="basic-nav-dropdown"
                      className="mobileMenu-subMenus right-caret"
                    >
                      {item.items.map((subItems) => (
                        <NavDropdown.Item
                          key={subItems.title}
                          href={subItems.url}
                          className="text-capitalize ecoatm-grey"
                        >
                          {subItems.title}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  );
                } else {
                  return (
                    <Nav.Link href={item.url} key={item.title}>
                      {item.display === "button" ? (
                        <span className="btn btn-success rounded-pill">
                          {item.title}
                        </span>
                      ) : (
                        <>
                          {item.icon && (
                            <img
                              src={item.icon}
                              className="mr-3 mb-1 ml-n3"
                              alt={item.title}
                              style={{ height: 20 }}
                            />
                          )}
                          {item.title}
                        </>
                      )}
                    </Nav.Link>
                  );
                }
              })}
            {/* {menuItems.fixedMenuItems && (
              <NavDropdown
                className="mobileMenu-subMenus right-caret"
                title="Contact Us"
                id="basic-nav-dropdown"
              >
                {menuItems.fixedMenuItems.map((subItem) => (
                  <NavDropdown.Item
                    className="text-capitalize ecoatm-grey"
                    key={subItem.title}
                    href={subItem.url}
                  >
                    {subItem.title}
                  </NavDropdown.Item>
                ))}
              </NavDropdown>)} */}
          </Nav>
        </div>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default React.memo(MobileMenu);
