import React from "react";
import "lazysizes";

const Image = ({ smallImage, mediumImage, largeImage }) => (
  <picture>
    <source media="(max-width: 576px)" srcSet={smallImage} />
    <source media="(max-width: 768px)" srcSet={mediumImage} />
    <img className="img-fluid" src={largeImage} alt="" />
  </picture>
  // <img
  //   data-sizes="auto"
  //   data-src={largeImage}
  //   data-srcset={`${smallImage} 576w, ${mediumImage} 768w, ${largeImage} 992w`}
  //   className="lazyload img-fluid"
  //   alt=""
  // />
);

const ResponsiveBanner = ({ url, ...rest }) => {
  return url ? (
    <a href={url} target="_blank" rel="noreferrer">
      {<Image {...rest} />}
    </a>
  ) : (
    <Image {...rest} />
  );
};

export default ResponsiveBanner;
