import React from "react";
import { useMediaQuery } from "react-responsive";
import { splitListByColumns } from "../../utils/utils";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

/** Simple component to show a list with title and optional icon, also
 * this component recieve the number of columns we want to show in the area
 * this number of columns could change depending on the size of the screen
 * for large screen is the same, for medium screen is half of the original number
 * for small screen we won't split in columns, we will display in one column
 *
 * TODO: is not good that the component has to calculate the number of columns
 * this task should be independant and agnostic to the component.
 */
const SimpleListWithTitle = ({
  title,
  items,
  icon,
  numberOfColumns = 1,
  containerCssClass = "",
}) => {
  /** This piece of code get the screen size using the react responsive library */
  const isBigScreen = useMediaQuery({ minWidth: 992 });
  const isMediumScreen = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const isSmallScreen = useMediaQuery({ maxWidth: 767 });

  // Default screen size will be Large
  let screenSize = "lg";

  if (isBigScreen) screenSize = "lg";
  if (isMediumScreen) screenSize = "md";
  if (isSmallScreen) screenSize = "sm";

  // After get the the screen size this method will return the original
  // list splitted in the number of columns according to the screen size
  const lists = splitListByColumns(items, numberOfColumns, screenSize);

  return (
    <div className={containerCssClass}>
      <h4 className="ecoatm-grey">{title}</h4>
      <div className="d-flex flex-row">
        {lists.map((list, index) => {
          return (
            <ul key={index} className="list-unstyled" style={{ flex: 1 }}>
              {list.map((item) => (
                <li key={item.id}>
                  {icon}
                  {item.url ? (
                    <Link className="ecoatm-grey" to={item.url}>
                      {item.title}
                    </Link>
                  ) : (
                    <>{item.title}</>
                  )}
                </li>
              ))}
            </ul>
          );
        })}
      </div>
    </div>
  );
};

SimpleListWithTitle.propTypes = {
  /** Array of items to display */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      /** Generic id to assign the key */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,

      /** The display value for plain text or <a> tag */
      title: PropTypes.string.isRequired,

      /** If this field is present instead of plain text the control
       * will render <a> tag with "src" value = url  */
      url: PropTypes.string,
    })
  ).isRequired,
  /** title to display before the content */
  title: PropTypes.string,

  /** if this optional value is present every element on the list will have the icon
   * we don't support multiple icon for each element */
  icon: PropTypes.object,

  /** Number of columns to display in large screens, this number could
   * change automatically if the screen is smaller */
  numberOfColumns: PropTypes.number,
};

SimpleListWithTitle.defaultProps = {
  /** The default number of columns if not specify is one */
  numberOfColumns: 1,
};

export default SimpleListWithTitle;
