import React, { useCallback, useEffect, useRef, useState } from "react";
import EcoMenu from "../ecoMenu";
import EcoAtmFooter from "../ecoATMComponents/ecoAtmFooter";
import MobileMenu from "../ecoATMComponents/mobileMenu/mobileMenu";
import LocationListItems from "../locationListItems";
import GoogleMap from "../googleMap";
import GoogleAutocomplete from "../googleAutocomplete";

import { useTracking } from "react-tracking";
import { getMenuItems } from "../../services/restGetLocalInformation";

import { useDoSearch2 } from "../../store/doSearch2.state";
import { useEvent } from "../../store/event.state";

import useGeoLocation from "../../utils/useGeolocation";
import CouponModal from "../../components/modal/modal";
import LabelWithIcon from "../labelWithIcon";
import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";

import { findClientMappingSettings } from "../../services/restSearchService";
const PromoLocator = (props) => {
  const thirdparty = props.match.params.thirdpartyid;

  //#region States Declaration
  //States for menu
  const [menuItems, setMenuItems] = useState({ items: [], fixedMenuItems: [] });

  const [googleMapKey, setGoogleMapKey] = useState(null);
  //States for searchs
  const [coordinates, setCoordinates] = useState({
    latitude: null,
    longitude: null,
  });

  const [searchParameters, setSearchParameters] = useState({
    countryId: null,
    latitude: null,
    longitude: null,
    state: null,
    city: null,
    pageSize: 20,
  });

  const couponModal = useRef();

  const eventState = useEvent(thirdparty);
  const mapContainer = useRef();

  //States for map
  const [googleMapIsReady, setGoogleMapIsReady] = useState(false);

  const [markerSelected, setMarkerSelected] = useState(-3);
  //#region

  // const geoLocation = useGeoLocation(true, true);
  const [geoLocation] = useGeoLocation(true, true);

  const doSearch2State = useDoSearch2(
    searchParameters,
    useCallback(() => {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }, [])
  );

  const locationsState = doSearch2State.locations;

  const setMenuConfigurationItems = async () => {
    const response = await getMenuItems();
    setMenuItems(response);
  };

  const onClickMarketMap = (markerID) => {
    if (markerSelected !== markerID) setMarkerSelected(markerID);
  };
  const { Track } = useTracking({
    page: "promoLocator",
    searchReqLogId: doSearch2State.doSearch2SearchReqLogId,
  });
  const { trackEvent } = useTracking();
  const handlerCouponClick = (
    Name,
    Address1,
    State,
    City,
    PostCode,
    PhoneNumber,
    Id
  ) => {
    trackEvent({
      eventType: EVENT_LOG_TYPE.logSearch,
      locationId: Id,
      responseType: SEARCH_RES_LOG_TYPE.locationPromotionPrint,
    });
    couponModal.current.open(
      Name,
      Address1,
      State,
      City,
      PostCode,
      PhoneNumber,
      eventState.event.EventImageURL,
      Id
    );
  };

  useEffect(() => {
    setMenuConfigurationItems();
  }, []);
  useEffect(() => {
    const getGoogleMapKey = async () => {
      if (!googleMapKey) {
        const response = await findClientMappingSettings();
        setGoogleMapKey(response.MappingSettings[0].APIKey);
      }
    };
    getGoogleMapKey();
  }, [googleMapKey]);

  useEffect(() => {
    if (coordinates.latitude && coordinates.longitude) {
      setSearchParameters({
        countryId: 1,
        state: null,
        city: null,
        latitude: coordinates.latitude,
        longitude: coordinates.longitude,
        pageSize: 20,
      });
    } else {
      setSearchParameters({
        countryId: 1,
        state: null,
        city: null,
        latitude: geoLocation.coordinates.lat,
        longitude: geoLocation.coordinates.lng,
        pageSize: 20,
      });
    }
  }, [coordinates, geoLocation]);

  useEffect(() => {
    setMarkerSelected(-1);
  }, [doSearch2State]);

  //console.log( eventState.event.BannersList.filter(x => x.title ==="Content3").map(ele=>ele.image));
  return (
    <>
      <Track>
        <CouponModal ref={couponModal}></CouponModal>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {eventState.event && eventState.event.content1 && (
                <div
                  className="my-1"
                  dangerouslySetInnerHTML={{
                    __html: eventState.event.content1,
                  }}
                />
              )}
              <div className="card shadow-sm p-0 mt-1 d-none d-lg-block">
                <EcoMenu menuConfiguration={menuItems} />
              </div>
              {eventState.event && eventState.event.content2 && (
                <div
                  className="my-1"
                  dangerouslySetInnerHTML={{
                    __html: eventState.event.content2,
                  }}
                />
              )}
              <div className="card shadow-sm p-0 mt-1 d-block d-lg-none">
                <MobileMenu menuItems={menuItems} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              {googleMapIsReady && (
                <GoogleAutocomplete
                  onGetLatLng={(result) => {
                    setCoordinates({
                      latitude: result.latitude,
                      longitude: result.longitude,
                    });
                  }}
                ></GoogleAutocomplete>
              )}
            </div>
            <div className="col-12 col-md-5 listmaplocator__locationListContainer">
              {locationsState.length > 0 && (
                <LocationListItems
                  locations={locationsState}
                  locationSelected={markerSelected}
                  onClick={onClickMarketMap}
                  showAccordion={true}
                  eventImage={eventState.event?.EventImageURL}
                  showLandingPageLink={false}
                  onCouponClick={handlerCouponClick}
                  getContainerSize={(height) => {
                    if (height > 886) {
                      height = 500;
                    }

                    if (height === 0) height = 500;

                    mapContainer.current.style.height = height + "px";
                  }}
                  couponButton={
                    <span className="float-right bullseye-red" role="button">
                      {
                        <LabelWithIcon
                          btIcon={{ btIconName: "printer", size: 20 }}
                          title="Print Coupon"
                        ></LabelWithIcon>
                      }
                    </span>
                  }
                />
              )}
            </div>
            <div className="col-12 col-md-7 listmaplocator__locationMapContainer">
              <div
                className="card shadow-sm p-0  rounded-1 mb-3"
                style={{ height: 886 }}
                ref={mapContainer}
              >
                {!(
                  locationsState.length === 0 &&
                  navigator.userAgent.indexOf("Chrome-Lighthouse") > -1
                ) &&
                  !doSearch2State.loading &&
                  googleMapKey && (
                    <GoogleMap
                      markers={locationsState}
                      googleApikey={googleMapKey}
                      onClick={onClickMarketMap}
                      markerSelected={markerSelected}
                      onMapReady={() => {
                        setGoogleMapIsReady(true);
                      }}
                      showLandingPageLink={false}
                      containerRef={mapContainer}
                    />
                  )}
              </div>
              <small>
                <a
                  href="https://www.google.com/policies/privacy/"
                  rel="noreferrer"
                  target="_blank"
                  className="float-right mt-n3 mr-1"
                >
                  Privacy Policy
                </a>
              </small>
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-2">
              {eventState.event && eventState.event.content3 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: eventState.event.content3,
                  }}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12 mt-1">
              {eventState.event && eventState.event.content4 && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: eventState.event.content4,
                  }}
                />
              )}
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row main-footer mt-3">
            <div className="col-12">
              <EcoAtmFooter />
            </div>
          </div>
        </div>
      </Track>
    </>
  );
};

export default PromoLocator;
