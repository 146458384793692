import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getSearchLocation } from "../services/restSearchService";
import { buildKiosksArray } from "../utils/ecoAtmUtils";
import { FixRelativePath, getCloseInformation } from "../utils/utils";

/***** Attributes names using in the system ******/
const aboutPracticeName = "about practice";
const profilePhotoName = "profile photo";

const getAttributeValue = (attributeCollection, attributeName, isImage) => {
  const attribute = attributeCollection.find(
    (attr) => attr.AttributeName.toLowerCase() === attributeName
  );

  if (attribute) {
    return isImage
      ? FixRelativePath(attribute.AttributeValue)
      : attribute.AttributeValue;
  }
  return "";
};

// const getDaysArray = (dailyHoursList) => {
//   return dailyHoursList.map((hour) => {
//     return {
//       day: hour.NameOfDay,
//       hours: hour.NoHoursAvailable ? "Close" : hour.HoursDisplayText,
//       isClosed: hour.NoHoursAvailable,
//     };
//   });
// };

export function useLocation(locationId, thirdPartyId) {
  const { i18n } = useTranslation();

  const [locationState, setLocationState] = useState({
    loading: true,
    location: {
      Id: 0,
      Name: "",
      Address1: "",
      Address4: "",
      StateAbbr: "",
      City: "",
      PhoneNumber: "",
      FacebookPageId: "",
      URL: "",
      Latitude: 0.0,
      Longitude: 0.0,
      DailyHoursList: [],
      Categories: [],
      RestAttributes: [],
      ImageFileUrl: "",
      RestLocationImage: [],
      RestLocationSocialMedia: {
        FacebookURL: "",
        InstagramURL: "",
        LinkedInURL: "",
        PinterestURL: "",
        TwitterURL: "",
        YelpURL: "",
      },

      aboutPractice: "",
      profilePhoto: "",
      aboutDentist: "",
      insuranceDescription: "",
      paymentOptions: "",

      servicesCollection: [],
      specialtyCollection: [],
      primarySpecialtyCollection: [],
      openCloseInfo: "",
      kiosks: [],
    },
  });

  useEffect(() => {
    const getLocationAsync = async () => {
      const location = await getSearchLocation(locationId, thirdPartyId); // 26683746
      //validate that location have attributes
      if (location.RestAttributes.length > 0) {
        // Get Attributes
        location.profilePhoto = getAttributeValue(
          location.RestAttributes,
          profilePhotoName,
          true
        );

        location.aboutPractice = getAttributeValue(
          location.RestAttributes,
          aboutPracticeName
        );
      }

      // Fix relative path for images
      location.ImageFileUrl = FixRelativePath(location.ImageFileUrl);

      // This is to avoid show the link on landing page
      location.URL = null;
      //console.log('location',location)
      location.openCloseInfo = " ";
      // getCloseInformation(
      //   location.DailyHoursList,
      //   location.BusinessHours.TimeZoneOffset
      //     ? location.BusinessHours.TimeZoneOffset
      //     : location.BusinessHours.TimeZoneName
      // );
      location.kiosks = buildKiosksArray(
        location.Attributes,
        "/images/us-payment-type-icon.svg"
      );
      // Fix Bussines Hours
      // location.openCloseAlert = davidfuncion(location.timezone, locations.dayarrays);
      // location.DailyHoursList = getDaysArray(location.DailyHoursList);

      setLocationState({ loading: false, location: location });
      //console.log("Get Location", location);
    };

    //console.log("Get location information");
    if (locationId || thirdPartyId) {
      getLocationAsync();
    }
  }, [locationId, thirdPartyId, i18n.language]);

  return locationState;
}
