import React from "react";
import { useTracking } from "react-tracking";

const LinkButton = ({ title, url, captureEvents, ...rest }) => {
  const { trackEvent } = useTracking();

  return (
    <a
      onClickCapture={(e) => {
        e.stopPropagation();
        captureEvents && trackEvent(captureEvents);
      }}
      className="btn btn-success rounded-pill px-4"
      href={url}
      role="button"
      {...rest}
    >
      {title}
    </a>
  );
};

export default LinkButton;
