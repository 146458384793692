import React from "react";
import { Helmet } from "react-helmet-async";
import { getStateAndCity } from "../../../utils/utils";

const EcoATMSEO = ({
  state,
  city,
  region1List,
  seoTitle,
  seoMetaDescription,
}) => {
  let resultStateCity = getStateAndCity(state, city, region1List);
  return (
    <>
      <Helmet>
        {seoTitle ? (
          <title>{seoTitle}</title>
        ) : (
          <title>{`${
            state ? resultStateCity : ""
          } Sell Phone, iPhone or Tablet | ecoATM`}</title>
        )}
        {seoMetaDescription ? (
          <meta name="description" content={seoMetaDescription} />
        ) : (
          <meta
            name="description"
            content={`Get fast cash for your phone${
              resultStateCity ? ` in ${resultStateCity} - ` : ". "
            }Sell your old phone for cash at a ecoATM kiosk near you. Find the nearest ecoATM location now.`}
          />
        )}
      </Helmet>
    </>
  );
};

export default EcoATMSEO;
