import i18n from "i18next";
const buildKiosksArray = (attributeArray, iconPaymentURL) => {
  const kiosksArray = [];

  const buildKiosk = (
    kioskPlacementName,
    KioskOOSName,
    KioskTabletName,
    kioskPaymentTypeName
  ) => {
    const kioskPlacement = getAttributeValueByName(
      kioskPlacementName,
      attributeArray
    );

    if (kioskPlacement) {
      let kioskInformation = {
        label: {
          title: i18n.t("locationListItmes.kiosk.location"),
          value: kioskPlacement,
          icon: "geo-alt-fill",
        },
      };

      const kioskOos = getAttributeValueByName(KioskOOSName, attributeArray);
      const kioskNoTablet = getAttributeValueByName(
        KioskTabletName,
        attributeArray
      );

      if (kioskOos === "True") {
        kioskInformation.alert = {
          title: i18n.t("locationListItmes.kiosk.outService"),
          cssClass: "alert-warning-ecoatm",
        };
      } else {
        if (kioskNoTablet === "True") {
          kioskInformation.alert = {
            title: i18n.t("locationListItmes.kiosk.noTablets"),
            cssClass: "alert-grey-ecoatm",
          };
        }
      }

      const kioskPaymentType = getAttributeValueByName(
        kioskPaymentTypeName,
        attributeArray
      );

      //Build Kiosk Payment Types
      if (kioskPaymentType) {
        kioskInformation.labelPayment = {
          title: i18n.t("locationListItmes.kiosk.paymentType"),
          value: kioskPaymentType,
          icon: iconPaymentURL,
        };
      }

      kiosksArray.push(kioskInformation);
    }
  };

  let kiosMultiLngPlacement = "kiosk placement";
  let kiosMultiLngPlacement2 = "kiosk 2 placement";

  if (i18n.language === "es") {
    kiosMultiLngPlacement = "Kiosk Placement Spanish";
    kiosMultiLngPlacement2 = "Kiosk 2 Placement Spanish";
  }

  // Kiosk 1
  buildKiosk(
    kiosMultiLngPlacement,
    "kiosk placement out of service",
    "kiosk tablets not accepted",
    "Kiosk 1 Payment Types"
  );
  // Kiosk 2
  buildKiosk(
    kiosMultiLngPlacement2,
    "kiosk 2 placement out of service",
    "kiosk 2 tablets not accepted",
    "Kiosk 2 Payment Types"
  );

  return kiosksArray;
};

const getAttributeValueByName = (name, attributeArray) => {
  var result = attributeArray.filter(
    (o) => o.AttributeName.toLowerCase() === name.toLowerCase()
  );

  return result.length > 0 ? result[0].AttributeValue : null; // or undefined
};

export { buildKiosksArray };
