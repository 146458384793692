import { useEffect, useState } from "react";
import { getEvent } from "../services/restEventService";

export function useEvent(thirdPartyId) {
  const [eventState, setEventState] = useState({
    loading: true,
    EventTitle: "",
    EventId: 0,
    ThirdPartyID: "",
    EventDescription: "",
    EventImageURL: "",
    BannersList: [
      {
        title: "",
        image: "",
      },
    ],
  });

  useEffect(() => {
    const getEventAsync = async () => {
      const event = await getEvent(thirdPartyId); // 26683746

      //validate that location have attributes

      //evaluate each attribute element to get the content images value
      event.Attributes.forEach((attribute) => {
        // console.log(attribute);
        if (attribute.AttributeName.toLowerCase() === "content1") {
          event.content1 = attribute.AttributeValue;
        }
        if (attribute.AttributeName.toLowerCase() === "content2") {
          event.content2 = attribute.AttributeValue;
        }
        if (attribute.AttributeName.toLowerCase() === "content3") {
          event.content3 = attribute.AttributeValue;
        }
        if (attribute.AttributeName.toLowerCase() === "content4") {
          event.content4 = attribute.AttributeValue;
        }
      });

      setEventState({ loading: false, event });
    };

    //console.log("Get location information");
    if (thirdPartyId) {
      console.log("Get event", thirdPartyId);
      getEventAsync();
    }
  }, [thirdPartyId]);

  return eventState;
}
