import React from "react";

const LabelWithIcon = ({
  btIcon: { btIconName, size },
  title,
  url,
  containerClass = "",
  labelClass = "",
  customIconUrl,
}) => {
  return (
    <div className={containerClass}>
      <div className="d-inline-block">
        {customIconUrl && (
          <img
            className="mr-2"
            style={{ width: size, height: size, verticalAlign: "middle" }}
            src={customIconUrl}
            alt="icon"
          />
        )}
        {!customIconUrl && (
          <svg
            className="mr-2"
            width={size}
            height={size}
            style={{ marginTop: "-0.4rem " }}
          >
            <use
              xlinkHref={`/images/b-icons/bootstrap-icons.svg#${btIconName}`}
            />
          </svg>
        )}
      </div>
      <span className={"align-middle " + labelClass}>
        {url ? <a href={url}>{title}</a> : title}
      </span>
    </div>
  );
};

export default LabelWithIcon;
