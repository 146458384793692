import React, { useState, useEffect } from "react";
import "./googleMap.scss";
import InfoMarker from "./infoMarker";

import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";
import { useTracking } from "react-tracking";

const GoogleMapMarker = ({
  text,
  pin,
  marker,
  onClick,
  showInfoWindow,
  showLandingPageLink,
}) => {
  const { trackEvent } = useTracking();
  const [show, setShow] = useState(showInfoWindow);
  useEffect(() => {
    setShow(showInfoWindow);
  }, [showInfoWindow]);
  return (
    <>
      <div
        className="pin"
        onClick={() => {
          trackEvent({
            eventType: EVENT_LOG_TYPE.logSearch,
            locationId: marker.Id,
            responseType: SEARCH_RES_LOG_TYPE.locationMapClicked,
          });
          setShow(true);
          if (onClick) {
            onClick(marker);
          }
        }}
      >
        <img id={`pin-img-${marker.Id}`} src={pin} alt="pin" />

        {text && <p>{text}</p>}
      </div>
      {show && (
        <InfoMarker
          marker={marker}
          showLandingPageLink={showLandingPageLink}
          onClose={() => setShow(false)}
        />
      )}
    </>
  );
};

export default GoogleMapMarker;
