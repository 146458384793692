import React from "react";
import BusinessHours from "../businessHours";
import LinkButton from "../linkButton/linkButton";
import LocationInformation from "../locationInformation";
import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";
import { useTranslation } from "react-i18next";
const LocationItem = ({
  showLandingPageLink,
  locationId,
  locationName,
  address1,
  stateAbbr,
  city,
  postCode,
  daysArray,
  country,
  extraInformation,
  showAccordion,
  landingPageURL,
  distance,
  openCloseInfo,
  coupon,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <LocationInformation
        address1={address1}
        city={city}
        stateAbbr={stateAbbr}
        postalCode={postCode}
        country={country}
        locationName={locationName}
        landingPageURL={showLandingPageLink ? landingPageURL : null}
        distance={distance}
      />
      <hr className="my-1" />
      {daysArray && daysArray.length > 0 && (
        <BusinessHours
          openCloseLabel={openCloseInfo}
          daysArray={daysArray}
          showAccordion={showAccordion}
        />
      )}
      {extraInformation}
      <div className="mt-4 mb-3 ml-2">
        <LinkButton
          url={`https://maps.google.com/maps?saddr=&daddr=${address1} ${city} ${
            stateAbbr || ""
          } ${postCode} ${country || ""}`}
          title={
            <span className="bl-text-bold-500">
              {t("locationListItmes.getDirections")}
              <img
                className="ml-2"
                style={{ marginBottom: 2, height: 10, verticalAlign: "middle" }}
                src="/images/right-arrow.svg"
                alt="right arrow"
              />
            </span>
          }
          target="_blank"
          captureEvents={{
            eventType: EVENT_LOG_TYPE.logSearch,
            locationId: locationId,
            responseType: SEARCH_RES_LOG_TYPE.directionsClicked,
          }}
          rel="noopener noreferrer"
        />
        {coupon}
      </div>
    </div>
  );
};

export default LocationItem;
