import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App2 from "./app2";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
// import TagManager from "react-gtm-module";

Sentry.init({
  dsn: "https://04d8be193d924a43afbe3132be8351a3@o445828.ingest.sentry.io/5711780",
  integrations: [new Integrations.BrowserTracing()],
  sampleRate: 0.1,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

// const tagManagerArgs = {
//   gtmId: "GTM-MWKPRFJ",
// };

// TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <HelmetProvider>
        <App2 />
      </HelmetProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
