import React from "react";

const EcoMenuSubMenu = ({ leftImage, menuItems }) => {
  return (
    <div className="d-flex justify-content-around">
      <div className="pr-3">
        <img src={leftImage} alt="eco atm"></img>
      </div>
      <div className="border-right">
        <ul className="nav flex-column bl-text-bold-500">
          {menuItems.map(({ title, url }) => {
            return (
              <li key={title} className="nav-item">
                <a className="nav-link py-1" href={url}>
                  {title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default EcoMenuSubMenu;
