import React, { Component } from "react";
import { FixRelativePath } from "../../utils/utils";
import "./coupon.scss";

class Coupon extends Component {
  render() {
    return (
      <div className="container coupon__promoPrint mt-4">
        {this.props.eventImage && (
          <div className="row">
            <div className="col-12">
              <img
                className="img-fluid"
                alt="coupon"
                src={FixRelativePath(this.props.eventImage)}
              ></img>
            </div>
          </div>
        )}
        <div className="row coupon_backgroundContainer">
          <div className="col-12 col-md-6">
            <h3>{this.props.locationName}</h3>
            <span>{this.props.address1}</span>
            <br></br>
            <span>
              {this.props.city}, {this.props.stateAbbr} {this.props.postCode}
            </span>
          </div>
          {this.props.phone && this.props.phone.length > 0 && (
            <div className="col-12 col-md-6">
              <h3>Contact</h3>
              <span>{this.props.phone}</span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Coupon;
