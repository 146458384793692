import React from "react";
import FooterAction from "./ecoAtmFooterAction";
import FooterMenuItems from "./ecoAtmFooterMenuItems";
import SubscribeForm from "./ecoAtmFooterSubscribeForm";
import PoliciesFooter from "./ecoAtmFooterPolicies";
import "./ecoAtmFooter.scss";

import { useTranslation } from "react-i18next";
const EcoAtmFooter = () => {
  const { t } = useTranslation();
  return (
    <footer className="mt-4 pt-4 container-fluid px-1 px-md-5">
      {/* FooterAction is the fist part of the footer, contains the Logo, social media links and the apps links
                    FooterMenuItems contains the items to access differents parts of the page like "contact", "how it works", etc.*/}
      <div className="row">
        <div className="col-12 col-md-4 col-lg-3">
          <FooterAction />
        </div>
        <div className="col-12 col-md-8 col-lg-9">
          <FooterMenuItems />
        </div>
      </div>
      {/* SubscribeForm contains the input and the button of the footer to subscribe a Newsletter*/}
      <div className="row">
        <div className="col-12">
          <SubscribeForm />
        </div>
      </div>

      {/* Text at the end of the footer, after the tag <hr> */}
      <div className="row footer-copyright text-justify py-3">
        <div className="col-12">
          {/* PoliciesFooter contains the options to access privacy policies, terms and conditions, Choices and Site map  */}
          <PoliciesFooter />
          <hr className="w-100 my-1" color="#FFF"></hr>
          <p style={{ fontSize: "0.9rem" }}>
            <small>{t("footer.rightsReservedText")}</small>
          </p>
        </div>
      </div>
    </footer>
  );
};
export default React.memo(EcoAtmFooter);
