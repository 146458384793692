import React from "react";

const Loading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center h-75">
      <img src={"/images/loading.gif"} />
    </div>
  );
};

export default Loading;
