import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useRef } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
} from "react-places-autocomplete";
import "./googleAutocomplete.scss";
import { useTranslation } from "react-i18next";

const GoogleAutocomplete = ({ onGetLatLng }, ref) => {
  const [googleAddress, setGoogleAddress] = useState({
    address: "",
    errorMessage: "",
    latitude: null,
    longitude: null,
  });

  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    clearGoogleAddress() {
      setGoogleAddress({
        address: "",
        errorMessage: "",
        latitude: null,
        longitude: null,
      });
    },
  }));

  const autoCompleteTextInput = useRef();

  const handleChange = (address) => {
    setGoogleAddress({
      address,
      errorMessage: "",
    });
  };

  const handleError = (status, clearSuggestions) => {
    console.log("Error from Google Maps API", status);
    clearSuggestions();
  };

  const handleSelect = (address, placeId, suggestion) => {
    if (suggestion) {
      geocodeByAddress(address).then((result) => {
        setGoogleAddress({
          address: result[0].formatted_address,
          errorMessage: "",
        });
        onGetLatLng({
          latitude: result[0].geometry.location.lat(),
          longitude: result[0].geometry.location.lng(),
        });

        if (autoCompleteTextInput) {
          autoCompleteTextInput.current.blur();
        }
      });
    }
  };

  const searchOptions = {
    types: ["(regions)"],
    componentRestrictions: { country: ["us"] },
  };
  const { t } = useTranslation();
  return (
    <>
      <PlacesAutocomplete
        onChange={handleChange}
        onSelect={handleSelect}
        value={googleAddress.address}
        onError={handleError}
        clearItemsOnError={true}
        searchOptions={searchOptions}
        highlightFirstSuggestion={true}
        shouldFetchSuggestions={true}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => {
          return (
            <div className="googleAutocomplete__search-bar-container">
              <div className="googleAutocomplete__search-input-container input-group ">
                <input
                  id="autoCompleteInput"
                  className="googleAutocomplete__form-control form-control rounded-pill my-2 googleAutocomplete__input-search"
                  type="text"
                  ref={autoCompleteTextInput}
                  {...getInputProps({
                    placeholder: t("googleAutocomplete.placeholder"),
                  })}
                ></input>
              </div>

              {suggestions.length > 0 && (
                <div className="googleAutocomplete__autocomplete-container">
                  {suggestions.map((suggestion, index) => {
                    const className = suggestion.active
                      ? "googleAutocomplete__suggestion-item--active"
                      : "googleAutocomplete__suggestion-item";

                    return (
                      <div
                        key={index}
                        {...getSuggestionItemProps(suggestion, { className })}
                      >
                        <span className="bl-text-bold-500">
                          {suggestion.formattedSuggestion.mainText}
                        </span>{" "}
                        <small>
                          {suggestion.formattedSuggestion.secondaryText}
                        </small>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        }}
      </PlacesAutocomplete>
    </>
  );
};

export default forwardRef(GoogleAutocomplete);
