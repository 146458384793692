import React, { useRef, useState } from "react";

import EcoMenuSubMenu from "./ecoMenuSubMenu";
import EcoMenuFixedSection from "./ecoMenuFixedSection";

import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { MenuItem, ControlledMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import "./ecoMenu.scss";

const EcoMenu = ({ menuConfiguration }) => {
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);

  const [leftImage, setLeftImage] = useState(null);
  const [menuSubItems, setSubMenuItems] = useState([]);

  const openMenu = (image, menuItems) => {
    setOpen(false);

    if (menuItems && menuItems.length > 0) {
      setLeftImage(image);
      setSubMenuItems(menuItems);
      setOpen(true);
    }
  };

  return (
    <>
      <ControlledMenu
        anchorRef={ref}
        offsetY={1}
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onMouseLeave={() => setOpen(false)}
        onMouseEnter={() => setOpen(true)}
      >
        <MenuItem>
          <div className="d-flex justify-content-around w-100">
            <div>
              <EcoMenuSubMenu leftImage={leftImage} menuItems={menuSubItems} />
            </div>
            <div>
              <EcoMenuFixedSection
                menuItems={menuConfiguration.fixedMenuItems}
              />
            </div>
          </div>
        </MenuItem>
      </ControlledMenu>

      <Navbar
        expand="lg"
        className="justify-content-between ecoMenu__navbar text-uppercase text-center"
        ref={ref}
        onMouseLeave={() => setOpen(false)}
      >
        <Navbar.Brand href="https://www.ecoatm.com/">
          {menuConfiguration.logo && (
            <img
              src={menuConfiguration.logo}
              className="d-inline-block align-top"
              style={{ height: 45 }}
              alt="ecoatm logo"
            ></img>
          )}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="w-100 justify-content-around align-items-center">
            {menuConfiguration.items.map((item) => {
              return (
                !item.showOnlyMobile && (
                  <Nav.Link
                    key={item.title}
                    href={item.url}
                    onMouseOver={() => openMenu(item.image, item.items)}
                  >
                    {item.display === "button" ? (
                      <span className="btn btn-success rounded-pill">
                        {item.title}
                      </span>
                    ) : (
                      item.title
                    )}
                  </Nav.Link>
                )
              );
            })}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default React.memo(EcoMenu);
