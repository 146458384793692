import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { postSuscribeEmail } from "../../../services/restEcoAtm";
import { useTranslation } from "react-i18next";

const EcoAtmFooterSubscribeForm = () => {
  const { register, handleSubmit, errors } = useForm({ mode: "onBlur" });
  const [inputEmail, setInputEmail] = useState("");
  const [successSuscription, setSuccessSuscription] = useState(false);
  const onSubmit = (data) => {
    let success = postSuscribeEmail(inputEmail);
    if (success) setSuccessSuscription(true);
  };
  /** onChange Input saves on the State */
  const fieldOnChange = (e) => {
    setInputEmail(e.target.value);
  };
  const { t } = useTranslation();
  return (
    <>
      <div className="col-md-4 col-xl-3 offset-md-7 p-0 col-12">
        {!successSuscription && (
          <form className="p-0 form-inline " onSubmit={handleSubmit(onSubmit)}>
            <div className="input-group w-100">
              <input
                type="text"
                id="inputEmail"
                name="inputEmail"
                className="form-control"
                placeholder={t("footer.subscribePlaceholder")}
                aria-label=""
                aria-describedby="basic-addon1"
                ref={register({
                  required: t("footer.subsctibeErrorEmailRequired"),
                  pattern: {
                    type: "pattern",
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: t("footer.subsctibeErrorEmailPattern"),
                  },
                })}
                errors={errors}
                onChange={fieldOnChange}
              />
              <div className="input-group-append">
                <button className="btn btn-success" type="submit">
                  {t("footer.subscribeButton")}
                </button>
              </div>
              {errors["inputEmail"] && (
                <div className="invalid-feedback d-block">
                  {errors["inputEmail"]?.message}
                </div>
              )}
            </div>
          </form>
        )}
        {successSuscription && (
          <div className="alert alert-success d-block" role="alert">
            {t("footer.subscribeThanksMessage")}
          </div>
        )}
      </div>

      <div
        className="col-md-4 col-xl-3 offset-md-7 p-0 col-12"
        style={{ fontSize: "0.9rem" }}
      >
        <small id="passwordHelpBlock" className="form-text text-justify ">
          {t("footer.subscribe")}
        </small>
      </div>
    </>
  );
};
export default EcoAtmFooterSubscribeForm;
