import React from "react";
import BusinessHoursTable from "./businessHoursTable";
import { Accordion } from "react-bootstrap";
import AccordtionCustomToggle from "./accordionCustomToggle";

const BusinessHoursAccordion = ({ title, daysArray }) => {
  return (
    <Accordion defaultActiveKey="1">
      <AccordtionCustomToggle eventKey="0">{title}</AccordtionCustomToggle>
      <Accordion.Collapse eventKey="0">
        <BusinessHoursTable daysArray={daysArray} />
      </Accordion.Collapse>
    </Accordion>
  );
};

export default BusinessHoursAccordion;
