import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Modal, Button } from "react-bootstrap";
import Coupon from "../../components/coupon/coupon";
import ReactToPrint from "react-to-print";
import { useTracking } from "react-tracking";
import { SEARCH_RES_LOG_TYPE, EVENT_LOG_TYPE } from "../../utils/constants";

export function CustomModal(props, ref) {
  const [isOpen, setIsOpen] = useState({
    isOpen: false,
    locationPromotion: {
      name: "",
      address: "",
      stateAbbr: "",
      city: "",
      postCode: "",
      phone: "",
      eventImage: "",
      Id: 0,
    },
  });

  const close = useCallback(
    () => setIsOpen({ isOpen: false, locationPromotion: {} }),
    []
  );
  const componentRef = useRef();

  useImperativeHandle(
    ref,
    () => ({
      open: (
        Name,
        Address1,
        State,
        City,
        PostCode,
        PhoneNumber,
        eventImage,
        Id
      ) =>
        setIsOpen({
          isOpen: true,
          locationPromotion: {
            name: Name,
            address: Address1,
            stateAbbr: State,
            city: City,
            postCode: PostCode,
            phone: PhoneNumber,
            eventImage: eventImage,
            Id: Id,
          },
        }),
      close,
    }),
    [close]
  );

  const handleEscape = useCallback(
    (event) => {
      if (event.keyCode === 27) close();
    },
    [close]
  );

  useEffect(() => {
    if (isOpen) document.addEventListener("keydown", handleEscape, false);
    return () => {
      document.removeEventListener("keydown", handleEscape, false);
    };
  }, [handleEscape, isOpen]);
  const { trackEvent } = useTracking();
  return isOpen.isOpen ? (
    <Modal animation={true} show={isOpen.isOpen} onHide={close} centered>
      <Modal.Header closeButton>
        <Modal.Title>Coupon</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactToPrint
          trigger={() => (
            <Button
              variant="success"
              onClickCapture={() => {
                trackEvent({
                  eventType: EVENT_LOG_TYPE.logSearch,
                  locationId: isOpen.locationPromotion.Id,
                  responseType: SEARCH_RES_LOG_TYPE.promotionPrint,
                });
              }}
            >
              Print Coupon
            </Button>
          )}
          content={() => componentRef.current}
        />
        <Coupon
          ref={componentRef}
          eventImage={isOpen.locationPromotion.eventImage}
          locationName={isOpen.locationPromotion.name}
          address1={isOpen.locationPromotion.address}
          postCode={isOpen.locationPromotion.postCode}
          stateAbbr={isOpen.locationPromotion.stateAbbr}
          city={isOpen.locationPromotion.city}
          phone={isOpen.locationPromotion.phone}
        ></Coupon>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : null;
}

export default forwardRef(CustomModal);
