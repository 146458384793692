import axios from "axios";
import * as Sentry from "@sentry/react";

// import { toast } from "react-toastify";

axios.defaults.baseURL = process.env.REACT_APP_URL_API;

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  console.error(error.response.data);
  Sentry.captureException(error);

  if (!expectedError) {
    console.log("Unexpected Service error");
    //logger.log(error);

    window.location.pathname = "/error/unexpected";
  } else if (error.response.status === 404) {
    console.log("Error: ", error);
    // toast.error("Location not found");
    window.location.pathname = "/error/not-found";
  } else if (error.response.status === 401) {
    console.log("Error: ", error);
    // toast.error("Unauthorized");
  }
  // console.log("Ocurrio un error", error.response.status);

  return Promise.reject(error);
});

export function setSecurityParameters() {
  // axios.defaults.headers.common["refer"] = "https://bullseyelocations.com";
  axios.defaults.params = {};
  axios.defaults.params["ClientId"] = process.env.REACT_APP_CLIENT_ID;
  axios.defaults.params["ApiKey"] = process.env.REACT_APP_API_KEY;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setSecurityParameters,
};
