import React from "react";
import { Link } from "react-router-dom";
import Address from "../address/address";

const LocationInformation = ({
  address1,
  address2,
  city,
  stateAbbr,
  postalCode,
  country,
  phone,
  locationName,
  contactName,
  contactPosition,
  landingPageURL,
  distance,
}) => {
  return (
    <>
      <div className="d-flex">
        <div className="flex-fill">
          {landingPageURL ? (
            <Link to={landingPageURL} onClick={(e) => e.stopPropagation()}>
              <span className="h5 d-inline-block">{locationName}</span>
            </Link>
          ) : (
            <h5>{locationName}</h5>
          )}
        </div>
        <div>
          {distance && distance > 0 ? <span>{distance}&nbsp;mi</span> : ""}
        </div>
      </div>

      <Address
        address1={address1}
        address2={address2}
        city={city}
        stateAbbr={stateAbbr}
        postalCode={postalCode}
        country={country}
        phone={phone}
      ></Address>
      {contactName && (
        <span>
          <b>Contact</b> {contactName}{" "}
          {contactPosition && <>, {contactPosition}</>}
        </span>
      )}
    </>
  );
};

export default LocationInformation;
